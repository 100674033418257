<template>
  <div>
    <el-container>
      <el-header style="padding: 0;">
        <header-view></header-view>
      </el-header>
        
          <router-view></router-view>
        
      
    </el-container>
  </div>
</template>

<script >
import HeaderView from './components/HeaderView.vue';
export default {
  name: "app",
  components: {HeaderView},
};
</script>

<style>


</style>
